/* eslint-disable import/no-anonymous-default-export */
import {
    DOMAINS_LIST,
    DOMAINS_LIST_SUCCESS,
    DOMAINS_LIST_ERROR,
    DOMAINS_DDLIST,
    DOMAINS_DDLIST_SUCCESS,
    DOMAINS_DDLIST_ERROR,
    DOMAINS_GET,
    DOMAINS_GET_SUCCESS,
    DOMAINS_GET_ERROR,
    // DOMAINS_GET_SEARCH,
    // DOMAINS_GET_SEARCH_SUCCESS,
    // DOMAINS_GET_SEARCH_ERROR,
    DOMAINS_NEW,
    DOMAINS_NEW_SUCCESS,
    DOMAINS_NEW_ERROR,
    DOMAINS_EDIT,
    DOMAINS_EDIT_SUCCESS,
    DOMAINS_EDIT_ERROR
} from '../types';

const initialState = {
    domains: [],
    domain: {},
    loading: false,
    error: false
}

export default function(state = initialState, action){
    switch(action.type){
        case DOMAINS_LIST:
            return {
                ...state,
                loading: true,
                domain: {},
            }
        case DOMAINS_LIST_SUCCESS:
            return {
                ...state,
                domains: action.payload,
                domain: {},
                loading: false,
                error: false,
            }
        case DOMAINS_LIST_ERROR:
            return {
                ...state,
                domains: [],
                domain: {},
                loading: false,
                error: true,
            }
        case DOMAINS_DDLIST:
            return {
                ...state,
                loading: true,
                domain: {}
            }
        case DOMAINS_DDLIST_SUCCESS:
            return {
                ...state,
                domains: action.payload,
                loading: false,
                error: false
            }
        case DOMAINS_DDLIST_ERROR:
            return {
                ...state,
                domains: [],
                loading: false,
                error: true,
                domain: {}
            }
        case DOMAINS_GET:
            return {
                ...state,
                loading: true
            }
        case DOMAINS_GET_SUCCESS:
            return {
                ...state,
                domain: action.payload,
                loading: false,
                error: false
            }
        case DOMAINS_GET_ERROR:
            return {
                ...state,
                error: true,
                domain: {}
            }
        // case DOMAINS_GET_SEARCH:
        //     return {
        //         ...state,
        //         loading: true
        //     }
        // case DOMAINS_GET_SEARCH_SUCCESS:
        //     switch (action.payload.field) {
        //         default:
        //             return {
        //                 ...state,
        //                 domain: action.payload,
        //                 loading: false,
        //                 error: false
        //             }
        //     }

        // case DOMAINS_GET_SEARCH_ERROR:
        //     switch (action.payload.field) {
        //         default:
        //             return {
        //                 ...state,
        //                 error: true,
        //                 domain: {}
        //             }
        //     }
        case DOMAINS_NEW:
            return {
                ...state
            }
        case DOMAINS_NEW_SUCCESS:
            return {
                ...state,
                error: false,
                domains: [...state.domains, action.payload]
            }
        case DOMAINS_NEW_ERROR:
            return {
                ...state,
                error: true
            }
        case DOMAINS_EDIT:
            return {
                ...state,
            }
        case DOMAINS_EDIT_SUCCESS:
            return {
                ...state,
                error: false,
                domain: action.payload,
                domains: state.domains.map(domain => domain.id === action.payload.id ? domain = action.payload : domain),
            }
        case DOMAINS_EDIT_ERROR:
            return {
                ...state,
                error: true
            }
        default:
            return state;
    }
}
