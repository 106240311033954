import {
    CHARGESLEVEL_LIST,
    CHARGESLEVEL_LIST_SUCCESS,
    CHARGESLEVEL_LIST_ERROR,
    CHARGESLEVEL_DDLIST,
    CHARGESLEVEL_DDLIST_SUCCESS,
    CHARGESLEVEL_DDLIST_ERROR,
} from '../types';

const initialState = {
    chargeslevel: [],
    chargelevel: {},
    loading: false,
    error: false
}

// eslint-disable-next-line import/no-anonymous-default-export
export default function(state = initialState, action){
    switch(action.type){
        case CHARGESLEVEL_LIST:
            return {
                ...state,
                loading: true,
                chargelevel: {},
            }
        case CHARGESLEVEL_LIST_SUCCESS:
            return {
                ...state,
                chargeslevel: action.payload,
                chargelevel: {},
                loading: false,
                error: false,
            }
        case CHARGESLEVEL_LIST_ERROR:
            return {
                ...state,
                chargeslevel: [],
                chargelevel: {},
                loading: false,
                error: true,
            }
        case CHARGESLEVEL_DDLIST:
            return {
                ...state,
                loading: true,
                chargelevel: {}
            }
        case CHARGESLEVEL_DDLIST_SUCCESS:
            return {
                ...state,
                chargeslevel: action.payload,
                loading: false,
                error: false
            }
        case CHARGESLEVEL_DDLIST_ERROR:
            return {
                ...state,
                chargeslevel: [],
                loading: false,
                error: true,
                chargelevel: {}
            }
        default:
            return state;
    }
}
