import {
    MASTERDATAS_LIST,
    MASTERDATAS_LIST_SUCCESS,
    MASTERDATAS_LIST_ERROR,
    MASTERDATAS_LIST_ALL,
    MASTERDATAS_LIST_ALL_SUCCESS,
    MASTERDATAS_LIST_ALL_ERROR,
    MASTERDATAS_DDLIST,
    MASTERDATAS_DDLIST_SUCCESS,
    MASTERDATAS_DDLIST_ERROR,
    MASTERDATAS_GET,
    MASTERDATAS_GET_SUCCESS,
    MASTERDATAS_GET_ERROR,
    MASTERDATAS_NEW,
    MASTERDATAS_NEW_SUCCESS,
    MASTERDATAS_NEW_ERROR,
    MASTERDATAS_NEW_RECORD,
    MASTERDATAS_EDIT,
    MASTERDATAS_EDIT_SUCCESS,
    MASTERDATAS_EDIT_ERROR
} from '../types';

const initialState = {
    masterdatas: [],
    businessTypeList: [],
    countriesList: [],
    competencesList: [],
    rolesList: [],
    reportTypeList: [],
    domainLevelList: [],
    personalSystemList: [],
    categoriesList: [],
    categoriesTypeList: [],
    orgLevelList: [],
    loading: false,
    error: false
}

// eslint-disable-next-line import/no-anonymous-default-export
export default function(state = initialState, action){
    switch(action.type){
        case MASTERDATAS_LIST:
            return {
                ...state,
                loading: true,
                masterdatas: {}
            }
        case MASTERDATAS_LIST_SUCCESS:
            return {
                ...state,
                masterdatasList: action.payload,
                loading: false,
                error: false,
            }
        case MASTERDATAS_LIST_ERROR:
            return {
                ...state,
                masterdatasList: [],
                loading: false,
                error: true,
                masterdatas: {}
            }
        case MASTERDATAS_LIST_ALL:
            return {
                ...state,
                loading: true,
                masterdatas: {}
            }
        case MASTERDATAS_LIST_ALL_SUCCESS:
            return {
                ...state,
                masterdatasList: action.payload,
                loading: false,
                error: false,
            }
        case MASTERDATAS_LIST_ALL_ERROR:
            return {
                ...state,
                masterdatasList: [],
                loading: false,
                error: true,
                masterdatas: {}
            }
        case MASTERDATAS_DDLIST:
            return {
                ...state,
                loading: true,
                masterdatas: {}
            }
        case MASTERDATAS_DDLIST_SUCCESS:
            switch(action.id){
                case process.env.REACT_APP_ROLES :
                    return {
                        ...state,
                        rolesList: action.payload,
                        loading: false,
                        error: false
                    }
                case process.env.REACT_APP_COUNTRIES :
                    return {
                        ...state,
                        countriesList: action.payload,
                        loading: false,
                        error: false
                    }
                case process.env.REACT_APP_REPORT_TYPE :
                    return {
                        ...state,
                        reportTypeList: action.payload,
                        loading: false,
                        error: false
                    }
                case process.env.REACT_APP_DOMAIN_LEVEL :
                    return {
                        ...state,
                        domainLevelList: action.payload,
                        loading: false,
                        error: false
                    }
                case process.env.REACT_APP_BUSINESS_TYPE :
                    return {
                        ...state,
                        businessTypeList: action.payload,
                        loading: false,
                        error: false
                    }
                case process.env.REACT_APP_PERSONALSYSTEM :
                    return {
                        ...state,
                        personalSystemList: action.payload,
                        loading: false,
                        error: false
                    }
                case process.env.REACT_APP_CATEGORIES :
                    return {
                        ...state,
                        categoriesList: action.payload,
                        loading: false,
                        error: false
                    }
                case process.env.REACT_APP_CATEGORIETYPE :
                    return {
                        ...state,
                        categoriesTypeList: action.payload,
                        loading: false,
                        error: false
                    }  
                case process.env.REACT_APP_COMPETENCE :
                    return {
                        ...state,
                        competencesList: action.payload,
                        loading: false,
                        error: false
                    }

                case process.env.REACT_APP_ORGLEVEL:
                    return {
                        ...state,
                        orgLevelList: action.payload,
                        loading: false,
                        error: false
                    }
                default:
                    return {
                        ...state,
                        masterdatas: action.payload,
                        loading: false,
                        error: false,
                    }
            }
        case MASTERDATAS_DDLIST_ERROR:
            return {
                ...state,
                loading: false,
                error: true,
                masterdatas: {}
            }
        case MASTERDATAS_GET:
            return {
                ...state,
                loading: true
            }
        case MASTERDATAS_GET_SUCCESS:
            return {
                ...state,
                masterdatas: action.payload,
                loading: false,
                error: false
            }
        case MASTERDATAS_GET_ERROR:
            return {
                ...state,
                error: true,
                masterdatas: {}
            }
        case MASTERDATAS_NEW:
            return {
                ...state
            }
        case MASTERDATAS_NEW_SUCCESS:
            return {
                ...state,
                masterdatas: {},
                error: false
            }
        case MASTERDATAS_NEW_ERROR:
            return {
                ...state,
                error: true
            }
        case MASTERDATAS_NEW_RECORD:
            return {
                ...state,
                masterdatas: {},
                error: false
            }
        case MASTERDATAS_EDIT:
            return {
                ...state
            }
        case MASTERDATAS_EDIT_SUCCESS:
            return {
                ...state,
                error: false,
                masterdatas: action.payload,
                //masterdatass: state.masterdatass.map(masterdatas => masterdatas.id === action.payload.id ? masterdatas = action.payload : masterdatas),
            }
        case MASTERDATAS_EDIT_ERROR:
            return {
                ...state,
                error: false
            }
        default:
            return state;
    }
}
