import {
    OPERATIVEUNITS_LIST,
    OPERATIVEUNITS_LIST_SUCCESS,
    OPERATIVEUNITS_LIST_ERROR,
    OPERATIVEUNITS_DDLIST,
    OPERATIVEUNITS_DDLIST_SUCCESS,
    OPERATIVEUNITS_DDLIST_ERROR,
} from '../types';
import {getItemsOperative} from "../../utils/asyncUtils";

const initialState = {
    operativeunits: [],
    operativeunit: {},
    loading: false,
    error: false
}

// eslint-disable-next-line import/no-anonymous-default-export
export default function(state = initialState, action){
    switch(action.type){
        case OPERATIVEUNITS_LIST:
            return {
                ...state,
                loading: true,
                operativeunit: {},
            }
        case OPERATIVEUNITS_LIST_SUCCESS:
            return {
                ...state,
                operativeunits: action.payload,
                operativeunit: {},
                loading: false,
                error: false,
            }
        case OPERATIVEUNITS_LIST_ERROR:
            return {
                ...state,
                operativeunits: [],
                operativeunit: {},
                loading: false,
                error: true,
            }
        case OPERATIVEUNITS_DDLIST:
            return {
                ...state,
                loading: true,
                operativeunit: {}
            }
        case OPERATIVEUNITS_DDLIST_SUCCESS:
            return {
                ...state,
                operativeunits: action.payload,
                loading: false,
                error: false
            }
        case OPERATIVEUNITS_DDLIST_ERROR:
            return {
                ...state,
                operativeunits: [],
                loading: false,
                error: true,
                operativeunit: {}
            }
        default:
            return state;
    }
}
