import { combineReducers } from 'redux';

import AreasReducer from './Areas.reducer';
import ChargesReducer from './Charges.reducer';
import ChargesLevelReducer from './ChargesLevel.reducer';
import CategoriesReducer from './Categories.reducer';
import ClientsReducer from './Clients.reducer';
import CompanyReducer from './Company.reducer';
import ConfigParametersReducer from './ConfigParameters.reducer';
import DepartmentReducer from './Department.reducer';
import DomainsReducer from './Domains.reducer';
import OperativeUnitReducer from './OperativeUnit.reducer';
import ParametersReducer from './Parameters.reducer';
import RolesReducer from './Roles.reducer';
import SectionsReducer from './Sections.reducer';
import UsersReducer from './Users.reducer';
import MasterDatasReducer from './MasterDatas.reducer';
import MaxDiffConfigsReducer from './MaxDiffConfig.reducer';
import ReportsReducer from './Reports.reducer';
import SupervisorsReducer from './Supervisors.reducer';
import SurveyJsonReducer from './SurveyJson.reducer';
import CollaboratorsReducer from './Collaborators.reducer';
import CompetencesReducer from './Competences.reducer';
import OrgLevelsReducer from './OrgLevels.reducer';
import DomainLevelsReducer from './DomainLevels.reducer';
import EmailDataReducer from './EmailData.reducer';
import SurveyReducer from './Survey.reducer';


export default combineReducers({
    areas: AreasReducer,
    charges: ChargesReducer,
    chargeslevel: ChargesLevelReducer,
    categories: CategoriesReducer,
    configparameters: ConfigParametersReducer,
    clients: ClientsReducer,
    company: CompanyReducer,
    department: DepartmentReducer,
    domains: DomainsReducer,
    operativeunit: OperativeUnitReducer,
    parameters: ParametersReducer,
    roles: RolesReducer,
    sections: SectionsReducer,
    users: UsersReducer,
    masterdatas: MasterDatasReducer,
    maxdiffconfigs: MaxDiffConfigsReducer,
    reports: ReportsReducer,
    supervisors: SupervisorsReducer,
    surveys: SurveyReducer,
    surveyjsons: SurveyJsonReducer,
    collaborators: CollaboratorsReducer,
    competences: CompetencesReducer,
    orglevels: OrgLevelsReducer,
    domainlevels: DomainLevelsReducer,
    emailData: EmailDataReducer,
});
