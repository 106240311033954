/* eslint-disable import/no-anonymous-default-export */
import {
    CONFIGPARAMETERS_LIST,
    CONFIGPARAMETERS_LIST_SUCCESS,
    CONFIGPARAMETERS_LIST_ERROR,
    CONFIGPARAMETERS_DDLIST,
    CONFIGPARAMETERS_DDLIST_SUCCESS,
    CONFIGPARAMETERS_DDLIST_ERROR,
    CONFIGPARAMETERS_GET,
    CONFIGPARAMETERS_GET_SUCCESS,
    CONFIGPARAMETERS_GET_ERROR,
    CONFIGPARAMETERS_GET_SEARCH,
    CONFIGPARAMETERS_GET_SEARCH_SUCCESS,
    CONFIGPARAMETERS_GET_SEARCH_ERROR,
    CONFIGPARAMETERS_NEW,
    CONFIGPARAMETERS_NEW_SUCCESS,
    CONFIGPARAMETERS_NEW_ERROR,
   // CONFIGPARAMETERS_NEW_RECORD,
    CONFIGPARAMETERS_EDIT,
    CONFIGPARAMETERS_EDIT_SUCCESS,
    CONFIGPARAMETERS_EDIT_ERROR
} from '../types';

const initialState = {
    configparameters: [],
    configparameter: {},
    loading: false,
    error: false
}

export default function(state = initialState, action){
    switch(action.type){
        case CONFIGPARAMETERS_LIST:
            return {
                ...state,
                loading: true,
                configparameter: {},
            }
        case CONFIGPARAMETERS_LIST_SUCCESS:
            return {
                ...state,
                configparameters: action.payload,
                configparameter: {},
                loading: false,
                error: false,
            }
        case CONFIGPARAMETERS_LIST_ERROR:
            return {
                ...state,
                configparameters: [],
                configparameter: {},
                loading: false,
                error: true,
            }
        case CONFIGPARAMETERS_DDLIST:
            return {
                ...state,
                loading: true,
                configparameter: {}
            }
        case CONFIGPARAMETERS_DDLIST_SUCCESS:
            return {
                ...state,
                configparameters: action.payload,
                loading: false,
                error: false
            }
        case CONFIGPARAMETERS_DDLIST_ERROR:
            return {
                ...state,
                configparameters: [],
                loading: false,
                error: true,
                configparameter: {}
            }
        case CONFIGPARAMETERS_GET:
            return {
                ...state,
                loading: true
            }
        case CONFIGPARAMETERS_GET_SUCCESS:
            return {
                ...state,
                configparameter: action.payload,
                loading: false,
                error: false
            }
        case CONFIGPARAMETERS_GET_ERROR:
            return {
                ...state,
                error: true,
                configparameter: {}
            }
        case CONFIGPARAMETERS_GET_SEARCH:
            return {
                ...state,
                loading: true
            }
        case CONFIGPARAMETERS_GET_SEARCH_SUCCESS:
            switch (action.payload.field) {
                default:
                    return {
                        ...state,
                        configparameter: action.payload,
                        loading: false,
                        error: false
                    }
            }

        case CONFIGPARAMETERS_GET_SEARCH_ERROR:
            switch (action.payload.field) {
                default:
                    return {
                        ...state,
                        error: true,
                        configparameter: {}
                    }
            }
        case CONFIGPARAMETERS_NEW:
            return {
                ...state
            }
        case CONFIGPARAMETERS_NEW_SUCCESS:
            return {
                ...state,
                error: false,
                configparameters: [...state.configparameters, action.payload]
            }
        case CONFIGPARAMETERS_NEW_ERROR:
            return {
                ...state,
                error: true
            }
        case CONFIGPARAMETERS_EDIT:
            return {
                ...state,
            }
        case CONFIGPARAMETERS_EDIT_SUCCESS:
            return {
                ...state,
                error: false,
                configparameter: action.payload,
                configparameters: state.configparameters.map(configparameter => configparameter.idconfig === action.payload.idconfig ? configparameter = action.payload : configparameter),
            }
        case CONFIGPARAMETERS_EDIT_ERROR:
            return {
                ...state,
                error: true
            }
        default:
            return state;
    }
}
