/* eslint-disable import/no-anonymous-default-export */
import {
    PARAMETERS_LIST,
    PARAMETERS_LIST_SUCCESS,
    PARAMETERS_LIST_ERROR,
    PARAMETERS_DDLIST,
    PARAMETERS_DDLIST_SUCCESS,
    PARAMETERS_DDLIST_ERROR,
    PARAMETERS_GET,
    PARAMETERS_GET_SUCCESS,
    PARAMETERS_GET_ERROR,
    PARAMETERS_GET_SEARCH,
    PARAMETERS_GET_SEARCH_SUCCESS,
    PARAMETERS_GET_SEARCH_ERROR,
    PARAMETERS_NEW,
    PARAMETERS_NEW_SUCCESS,
    PARAMETERS_NEW_ERROR,
    PARAMETERS_EDIT,
    PARAMETERS_EDIT_SUCCESS,
    PARAMETERS_EDIT_ERROR
} from '../types';

const initialState = {
    parameters: [],
    parameter: {},
    loading: false,
    error: false
}

export default function(state = initialState, action){
    switch(action.type){
        case PARAMETERS_LIST:
            return {
                ...state,
                loading: true,
                parameter: {},
            }
        case PARAMETERS_LIST_SUCCESS:
            return {
                ...state,
                parameters: action.payload,
                parameter: {},
                loading: false,
                error: false,
            }
        case PARAMETERS_LIST_ERROR:
            return {
                ...state,
                parameters: [],
                parameter: {},
                loading: false,
                error: true,
            }
        case PARAMETERS_DDLIST:
            return {
                ...state,
                loading: true,
                parameter: {}
            }
        case PARAMETERS_DDLIST_SUCCESS:
            return {
                ...state,
                parametersList: action.payload,
                loading: false,
                error: false
            }
        case PARAMETERS_DDLIST_ERROR:
            return {
                ...state,
                parametersList: [],
                loading: false,
                error: true,
                parameter: {}
            }
        case PARAMETERS_GET:
            return {
                ...state,
                loading: true
            }
        case PARAMETERS_GET_SUCCESS:
            return {
                ...state,
                parameter: action.payload,
                loading: false,
                error: false
            }
        case PARAMETERS_GET_ERROR:
            return {
                ...state,
                error: true,
                parameter: {}
            }
        case PARAMETERS_GET_SEARCH:
            return {
                ...state,
                loading: true
            }
        case PARAMETERS_GET_SEARCH_SUCCESS:
            switch (action.payload.field) {
                default:
                    return {
                        ...state,
                        parameter: action.payload,
                        loading: false,
                        error: false
                    }
            }
            
        case PARAMETERS_GET_SEARCH_ERROR:
            switch (action.payload.field) {
                default:
                    return {
                        ...state,
                        error: true,
                        parameter: {}
                    }
            }
        case PARAMETERS_NEW:
            return {
                ...state
            }
        case PARAMETERS_NEW_SUCCESS:
            return {
                ...state,
                error: false,
                parameters: [...state.parameters, action.payload]
            }
        case PARAMETERS_NEW_ERROR:
            return {
                ...state,
                error: true
            }
        case PARAMETERS_EDIT:
            return {
                ...state,
            }
        case PARAMETERS_EDIT_SUCCESS:
            return {
                ...state,
                error: false,
                parameter: action.payload,
                parameters: state.parameters.map(parameter => parameter.id === action.payload.id ? parameter = action.payload : parameter),
            }
        case PARAMETERS_EDIT_ERROR:
            return {
                ...state,
                error: true
            }
        default:
            return state;
    }
}