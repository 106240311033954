
import axios from 'axios';
import {getAccessToken} from './DatalentSessionStorage';
import Swal from 'sweetalert2';
import {navigate} from "hookrouter";

const AxiosClient = axios.create({
    baseURL: `${process.env.REACT_APP_BACKEND_URL}`
});

// Add a request interceptor
AxiosClient.interceptors.request.use(
    config => {
        const token = getAccessToken();
        if (token) {
            config.headers = { 'x-token': token};
        }
        return config;
    },
    error => {
        Promise.reject(error);
    });


// Add a response interceptor
AxiosClient.interceptors.response.use((response) => {
    if (response.data.status === 403 || response.data.status === 500) {
        Swal.fire({
            icon:'error',
            title: response.data.message === 'Token Vencido' ? 'Caducidad de Sesión' : "Error",
            text: response.data.message === 'Token Vencido' ? 'Ha caducado la sesión debe volver a iniciar sesión' : response.data.message
        });
        navigate('/');
        sessionStorage.clear();
    }
    return response
}, function (error) {
    const originalRequest = error.config;
    // Swal.fire({
    //     icon:'error',
    //     title: 'Caducidad de Sesión',
    //     text: 'Ha caducado la sesión debe volver a iniciar sesión'
    // });
    // history.push('/');


   /* if (error.response.status === 401) {
        Swal.fire({
            icon:'error',
            title: 'No hay rol asignado',
            text: 'El usuario no posee un rol asignado para Datalent'
        });
        history.push('/login');
        return Promise.reject(error);
    }

        if (error.response.status === 403) {
        Swal.fire({
            icon:'error',
            title: 'Caducidad de Sesión',
            text: 'Ha caducado la sesión debe volver a iniciar sesión'
        });
        history.push('/');

        return Promise.reject(error);
    }

    if (error.response.status === 401 && !originalRequest._retry) {

        originalRequest._retry = true;
        const refreshToken = getRefreshToken();
        return AxiosClient.post('/auth/token',
            {
                "refresh_token": refreshToken
            })
            .then(res => {
                if (res.status === 201) {
                    setToken(res.data);
                    AxiosClient.defaults.headers.common['authorization'] = 'Bearer ' + getAccessToken();
                    return AxiosClient(originalRequest);
                }
            })
    }
    return Promise.reject(error);*/
 });

//AxiosClient.defaults.headers.common['authorization'] = sessionStorage.getItem("jwtToken");

export default AxiosClient;
