
import AxiosClient from '../config/AxiosClient';

export const getItemsOperative = async (countryId, companyId) =>{
    const response   = await AxiosClient.get(`${process.env.REACT_APP_OPERATIVEUNITS}/list/${countryId}/${companyId}`);
    let   items = {};
    if(response) items = response;
    return items;
}

export const getItemsSections = async (companyId) =>{
    const response   = await AxiosClient.get(`${process.env.REACT_APP_SECTIONS}/list/${companyId}`);
    let   items = {};
    if(response) items = response;
    return items;
}

export const addRoles = async (record, isNew) => {
    let error = false;
    if (isNew){
        const response = await AxiosClient.post(`${process.env.REACT_APP_ROLES}/add`, record)
        if (response) error = response.data;
    }else{
        const response = await AxiosClient.put(`${process.env.REACT_APP_ROLES}/${record.id}`, record)
        if (response) error = response.data;
    }
    return error
}

export const addClients = async (record, isNew) => {
    let resp = false;
    if (isNew){
        const response = await AxiosClient.post(`${process.env.REACT_APP_CLIENTS}`, record)
        if (response) resp = response.data;
    }else{
        const response = await AxiosClient.put(`${process.env.REACT_APP_CLIENTS}/${record.id}`, record)
        if (response) resp = response.data;
    }
    return resp
}

export const addDomains = async (record, isNew) => {
    let resp = false;
    if (isNew){
        const response = await AxiosClient.post(`${process.env.REACT_APP_DOMAINS}`, record)
        if (response) resp = response.data;
    }else{
        const response = await AxiosClient.put(`${process.env.REACT_APP_DOMAINS}/${record.id}`, record)
        if (response) resp = response.data;
    }
    return resp
}

export const addCompetences = async (record, isNew) => {
    let resp = false;
    if (isNew){
        const response = await AxiosClient.post(`${process.env.REACT_APP_COMPETENCES}`, record)
        if (response) resp = response.data;
    }else{
        const response = await AxiosClient.put(`${process.env.REACT_APP_COMPETENCES}`, record)
        if (response) resp = response.data;
    }
    return resp
}

export const addCategories = async (record, isNew) => {
    let resp = false;
    if (isNew){
        const response = await AxiosClient.post(`${process.env.REACT_APP_CATEGORIESBYCOMPANY}`, record)
        if (response) resp = response.data;
    }else{
        const response = await AxiosClient.put(`${process.env.REACT_APP_CATEGORIESBYCOMPANY}/${record.id}`, record)
        if (response) resp = response.data;
    }
    return resp
}

export const addCompetenceOrg = async (record, isNew) => {
    let resp = false;
    if (isNew){
        const response = await AxiosClient.post(`${process.env.REACT_APP_CONFIGPARAMETERS}`, record)
        if (response) resp = response.data;
    }else{
        const response = await AxiosClient.put(`${process.env.REACT_APP_CONFIGPARAMETERS}/${record.idconfig}`, record)
        if (response) resp = response.data;
    }
    return resp
}

export const addUsers = async (record, isNew) => {
    let error = false;
    if (isNew){
        const response = await AxiosClient.post(`${process.env.REACT_APP_USERS}`, record)
        if (response) error = response.data;

    }else{
        const response = await AxiosClient.put(`${process.env.REACT_APP_USERS}/${record.id}`, record)
        if (response) error = response.data;

    }
    return error
}


export const addMaxDiffConfig = async (record, isNew) => {
    let error = false;
    if (isNew){
        const response = await AxiosClient.post(`${process.env.REACT_APP_MAXDIFFCONFIG}`, record)
        if (response) error = response.data;
    }else{
        const response = await AxiosClient.put(`${process.env.REACT_APP_MAXDIFFCONFIG}/${record.id}`, record)
        if (response) error = response.data;
    }
    return error
}

export const addBusinessType = async (record, isNew) => {
    let error = false;
    if (isNew){
        const response = await AxiosClient.post(`${process.env.REACT_APP_MASTERDATAS}`, record)
        if (response) error = response.data;

    }else{
        const response = await AxiosClient.put(`${process.env.REACT_APP_MASTERDATAS}/${record.masterdataid}`, record)
        if (response) error = response.data;

    }
    return error
}

export const levelsListbyClient = async (record) => {
    let resp = false;
        const response = await AxiosClient.get(`${process.env.REACT_APP_ORG_LEVELS}/list/${record}`)
        if (response) resp = response.data;

    return resp
}

export const clientsandlevels =  async (clients) => {
    let info = []
    let resp = false;
        let response = await AxiosClient.get(`${process.env.REACT_APP_CLIENTS}`)
        const selectedLevels = await AxiosClient.get(`/levelbycompany`)
        if (response) {
            response.data.result.map( async (client,index)=> {
                let orden =
                    {
                        'N1': [],
                        'N2': [],
                        'N3': [],
                        'N4': [],
                    }
                let levelsbyclient = selectedLevels.data.result.filter((l)=>{return l.clientid == client.id})
                AxiosClient.get(`${process.env.REACT_APP_ORG_LEVELS}/list/${client.id}-${client.schema}`)
                    .then((level)=> {
                        if(level){

                            if(levelsbyclient.length>0){
                                levelsbyclient.map(l=>{
                                    if(l.masterdata.name === process.env.REACT_APP_ORGLEVEL_N1 ) orden['N1'].push(l.siplevel)
                                    if(l.masterdata.name === process.env.REACT_APP_ORGLEVEL_N2 ) orden['N2'].push(l.siplevel)
                                    if(l.masterdata.name === process.env.REACT_APP_ORGLEVEL_N3 ) orden['N3'].push(l.siplevel)
                                    if(l.masterdata.name === process.env.REACT_APP_ORGLEVEL_N4 ) orden['N4'].push(l.siplevel)
                                })
                            }

                            response.data.result[index].levels = level.data.result;
                            response.data.result[index].selectedlevels = orden
                            client.levels = level.data.result
                            client.selectedlevels = orden;
                        }else{
                            response.data.result[index].levels = [];
                            client.levels = level.data.result
                            client.selectedlevels = orden;
                        }

                        info.push(client)
                    })
                    .catch((err) => {
                    console.log('err', err)
                    response.data.result[index].levels = [];
                        client.levels = []
                        client.selectedlevels = orden;
                })
            })
            resp = response.data.result
        }
    return resp
}

export const selectedLevels = async (clients) =>{
    let resp = false;
    const response = await AxiosClient.get(`${process.env.REACT_APP_LEVELS_BY_COMPANY}`);
    console.log(response)
    if(response) resp= response.data;
    return resp
}

export const levelsSIPList = async (client) =>{
    let resp = false;
    const response = await AxiosClient.get(`${process.env.REACT_APP_ORG_LEVELS}/list/${client.id}`)
    if(response) resp= response.data;
    return resp
}

export const createLevelsByClient = async (record, isNew) => {
    console.log(record[0].id)
    let resp = false;
    if(isNew){
        const response = await AxiosClient.post(`${process.env.REACT_APP_LEVELS_BY_COMPANY}`, record)
        console.log('new',response)
        if (response) resp = response.data;
    }else{
        const response = await AxiosClient.put(`${process.env.REACT_APP_LEVELS_BY_COMPANY}/${record[0].id}`, record[0])
        console.log('update',response)
        if (response) resp = response.data;
    }



    return resp
}

export const sendEmailBatch = async (data) => {
    let resp = false;
    const response = await AxiosClient.post(`${process.env.REACT_APP_EMAIL_BATCH }`, data)
    if (response) resp = response.data;
    return resp
}

export const addDomainLevel = async (record, isNew) => {
    let resp = false;
    if (isNew){
        const response = await AxiosClient.post(`${process.env.REACT_APP_DOMAIN_LEVELS_BY_COMPANY}`, record)
        if (response) resp = response.data;
    }else{
        const response = await AxiosClient.put(`${process.env.REACT_APP_DOMAIN_LEVELS_BY_COMPANY}/${record.id}`, record)
        if (response) resp = response.data;
    }
    return resp
}

export const addItemCompetences = async (record, isNew) => {
    let resp = false;
    if (isNew){
        const response = await AxiosClient.post(`${process.env.REACT_APP_MASTERDATAS}`, record)
        if (response) resp = response.data;
    }else{
        const response = await AxiosClient.put(`${process.env.REACT_APP_MASTERDATAS}/${record.masterdataid}`, record)
        if (response) resp = response.data;
    }
    return resp
}

export const addEmail = async (record, isNew) => {
    let error = false;
    if (isNew){
        const response = await AxiosClient.post(`${process.env.REACT_APP_EMAILDATA}/`, record)
        if (response) error = response.data;

    }else{
        const response = await AxiosClient.put(`${process.env.REACT_APP_EMAILDATA}/${record.id}`, record)
        if (response) error = response.data;

    }
    return error
}
export const addMaxdiffConfig = async (record,type) => {
    let resp = false;
    const response = await AxiosClient.put(`${process.env.REACT_APP_MAXDIFFCONFIG}/type/${type}`, record)
    if (response) resp = response.data;
    return resp
}

export const surveyJsonNoProcessList = async (params) =>{  
    let resp = false;    
    //const response = await AxiosClient.get(`/surveyjson/'${params.surveyDateFrom}'/'${params.surveyDateTo}`) 
    
    const response = await AxiosClient.get(`/unprocessedsurveybydaterange/'${params.surveyDateFrom}'/'${params.surveyDateTo}`)
    if(response) resp= response.data;
    return resp
}

export const deactivateSurvey = async (record) => {
    let resp = false;
    const response = await AxiosClient.put(`${process.env["REACT_APP_SURVEY"]}`, record)
    if (response) resp = response.data;
    return resp
}

export const uploadExcelFile = async (record) => {
    console.log(record)
    const formData = new FormData();
          formData.append("myFile", record.myFile);
    let resp = false;
    const response = await AxiosClient.post(`${process.env.REACT_APP_EXCEL_FILE}`, formData)
    if (response) resp = response.data;
    return resp
}
