import {
    DOMAINLEVELS_LIST,
    DOMAINLEVELS_LIST_SUCCESS,
    DOMAINLEVELS_LIST_ERROR,
    DOMAINLEVELS_DDLIST,
    DOMAINLEVELS_DDLIST_SUCCESS,
    DOMAINLEVELS_DDLIST_ERROR,
    DOMAINLEVELS_GET_ONE,
    DOMAINLEVELS_GET_ONE_SUCCESS,
    DOMAINLEVELS_GET_ONE_ERROR,
} from '../types';

const initialState = {
    domainlevels: [],
    domainlevel: {},
    loading: false,
    error: false
}

// eslint-disable-next-line import/no-anonymous-default-export
export default function(state = initialState, action){
    switch(action.type){
        case DOMAINLEVELS_LIST:
            return {
                ...state,
                loading: true,
                domainlevel: {},
            }
        case DOMAINLEVELS_LIST_SUCCESS:
            return {
                ...state,
                domainlevels: action.payload,
                domainlevel: {},
                loading: false,
                error: false,
            }
        case DOMAINLEVELS_LIST_ERROR:
            return {
                ...state,
                domainlevels: [],
                domainlevel: {},
                loading: false,
                error: true,
            }
        case DOMAINLEVELS_DDLIST:
            return {
                ...state,
                loading: true,
                domainlevel: {}
            }
        case DOMAINLEVELS_DDLIST_SUCCESS:
            return {
                ...state,
                domainlevels: action.payload,
                loading: false,
                error: false
            }
        case DOMAINLEVELS_DDLIST_ERROR:
            return {
                ...state,
                domainlevels: [],
                loading: false,
                error: true,
                domainlevel: {}
            }
        case DOMAINLEVELS_GET_ONE:
            return {
                ...state,
                loading: true,
            }
        case DOMAINLEVELS_GET_ONE_SUCCESS:
            return {
                ...state,
                domainlevel: action.payload,
                loading: false,
                error: false
            }
        case DOMAINLEVELS_GET_ONE_ERROR:
            return {
                ...state,
                loading: false,
                error: true,
                domainlevel: {}
            }
        default:
            return state;
    }
}
