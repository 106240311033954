import {
    COLLABORATORS_LIST,
    COLLABORATORS_LIST_SUCCESS,
    COLLABORATORS_LIST_ERROR,
    COLLABORATORS_DDLIST,
    COLLABORATORS_DDLIST_SUCCESS,
    COLLABORATORS_DDLIST_ERROR,
} from '../types';

const initialState = {
    collaborators: [],
    collaborator: {},
    loading: false,
    error: false
}

// eslint-disable-next-line import/no-anonymous-default-export
export default function(state = initialState, action){
    switch(action.type){
        case COLLABORATORS_LIST:
            return {
                ...state,
                loading: true,
                collaborator: {},
            }
        case COLLABORATORS_LIST_SUCCESS:
            return {
                ...state,
                collaborators: action.payload,
                collaborator: {},
                loading: false,
                error: false,
            }
        case COLLABORATORS_LIST_ERROR:
            return {
                ...state,
                collaborators: [],
                collaborator: {},
                loading: false,
                error: true,
            }
        case COLLABORATORS_DDLIST:
            return {
                ...state,
                loading: true,
                collaborator: {}
            }
        case COLLABORATORS_DDLIST_SUCCESS:
            return {
                ...state,
                collaborators: action.payload,
                loading: false,
                error: false
            }
        case COLLABORATORS_DDLIST_ERROR:
            return {
                ...state,
                collaborators: [],
                loading: false,
                error: true,
                collaborator: {}
            }
        default:
            return state;
    }
}
