/* eslint-disable import/no-anonymous-default-export */
import {
    ROLES_LIST,
    ROLES_LIST_SUCCESS,
    ROLES_LIST_ERROR,
    ROLES_DDLIST,
    ROLES_DDLIST_SUCCESS,
    ROLES_DDLIST_ERROR,
    ROLES_GET,
    ROLES_GET_SUCCESS,
    ROLES_GET_ERROR,
    ROLES_GET_SEARCH,
    ROLES_GET_SEARCH_SUCCESS,
    ROLES_GET_SEARCH_ERROR,
    ROLES_NEW,
    ROLES_NEW_SUCCESS,
    ROLES_NEW_ERROR,
    ROLES_EDIT,
    ROLES_EDIT_SUCCESS,
    ROLES_EDIT_ERROR
} from '../types';

const initialState = {
    roles: [],
    role: {},
    loading: false,
    error: false
}

export default function(state = initialState, action){
    switch(action.type){
        case ROLES_LIST:
            return {
                ...state,
                loading: true,
                role: {},
            }
        case ROLES_LIST_SUCCESS:
            return {
                ...state,
                roles: action.payload,
                role: {},
                loading: false,
                error: false,
            }
        case ROLES_LIST_ERROR:
            return {
                ...state,
                roles: [],
                role: {},
                loading: false,
                error: true,
            }
        case ROLES_DDLIST:
            return {
                ...state,
                loading: true,
                role: {}
            }
        case ROLES_DDLIST_SUCCESS:
            return {
                ...state,
                rolesList: action.payload,
                loading: false,
                error: false
            }
        case ROLES_DDLIST_ERROR:
            return {
                ...state,
                rolesList: [],
                loading: false,
                error: true,
                role: {}
            }
        case ROLES_GET:
            return {
                ...state,
                loading: true
            }
        case ROLES_GET_SUCCESS:
            return {
                ...state,
                role: action.payload,
                loading: false,
                error: false
            }
        case ROLES_GET_ERROR:
            return {
                ...state,
                error: true,
                role: {}
            }
        case ROLES_GET_SEARCH:
            return {
                ...state,
                loading: true
            }
        case ROLES_GET_SEARCH_SUCCESS:
            switch (action.payload.field) {
                default:
                    return {
                        ...state,
                        role: action.payload,
                        loading: false,
                        error: false
                    }
            }

        case ROLES_GET_SEARCH_ERROR:
            switch (action.payload.field) {
                default:
                    return {
                        ...state,
                        error: true,
                        role: {}
                    }
            }
        case ROLES_NEW:
            return {
                ...state
            }
        case ROLES_NEW_SUCCESS:
            return {
                ...state,
                new_error: false,
                role: action.payload,
                roles: state.roles.map(role => role.id === action.payload.id ? role = action.payload : role)
                // roles: [...state.roles, action.payload]
            }
        case ROLES_NEW_ERROR:
            return {
                ...state,
                new_error: true
            }
        case ROLES_EDIT:
            return {
                ...state,
            }
        case ROLES_EDIT_SUCCESS:
            return {
                ...state,
                error: false,
                role: action.payload,
                roles: state.roles.map(role => role.id === action.payload.id ? role = action.payload : role),
            }
        case ROLES_EDIT_ERROR:
            return {
                ...state,
                error: true
            }
        default:
            return state;
    }
}
