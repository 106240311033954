import React, {Suspense, lazy} from "react";
import ReactDOM from "react-dom";
import "../src/styles/styles.css";
import { Provider } from 'react-redux';
import { ThemeProvider, createMuiTheme } from "@material-ui/core/styles";
import configureStore from './store/store';

import reportWebVitals from "./reportWebVitals";

// import DatalentApp from "./DatalentApp";
const DatalentApp = lazy(() => import('./DatalentApp'))
const theme = createMuiTheme({
    typography: {
        fontFamily: [
            'sans-serif',
            '-apple-system',
            'BlinkMacSystemFont',
            '"Segoe UI"',
            'Roboto',
            '"Helvetica Neue"',
            '"Apple Color Emoji"',
            '"Segoe UI Emoji"',
            '"Segoe UI Symbol"',
        ].join(','),
    },
  palette: {
    primary: {
      main: "#00225C",
    },
    secondary: {
      main: "#0E77BD",
      light: "#DBEBF5"
    },
      success: {
          main: "#4caf50",
      },
      info: {
          main: "#ffffff",
      },
      error: {
          main: "#F44336"
      },
      warning: {
          main: "#ff9800"
      },
      background: {
        paper: "#ffffff",
        paper2: "#DBEBF5",
        paper3: "#efefef"
      }
  },
});

const store = configureStore();

ReactDOM.render(
        <Provider store={store}>
            <ThemeProvider theme={theme}>
                <Suspense fallback={''}>
                    <DatalentApp />
                </Suspense>
            </ThemeProvider>
        </Provider>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
