import {
    ORG_LEVELS_LIST,
    ORG_LEVELS_LIST_SUCCESS,
    ORG_LEVELS_LIST_ERROR,
    ORG_LEVELS_BY_COMPANY_LIST,
    ORG_LEVELS_BY_COMPANY_LIST_SUCCESS,
    ORG_LEVELS_BY_COMPANY_LIST_ERROR
} from '../types';

const initialState = {
    orglevels: [],
    orglevel: {},
    loading: false,
    error: false
}

// eslint-disable-next-line import/no-anonymous-default-export
export default function(state = initialState, action){
    switch(action.type){
        case ORG_LEVELS_LIST:
            return {
                ...state,
                loading: true,
                orglevel: {},
            }
        case ORG_LEVELS_LIST_SUCCESS:
            return {
                ...state,
                orglevels: action.payload,
                orglevel: {},
                loading: false,
                error: false,
            }
        case ORG_LEVELS_LIST_ERROR:
            return {
                ...state,
                orglevels: [],
                orglevel: {},
                loading: false,
                error: true,
            }
        case ORG_LEVELS_BY_COMPANY_LIST:
            return {
                ...state,
                loading: true,
                orglevel: {},
            }
        case ORG_LEVELS_BY_COMPANY_LIST_SUCCESS:
            return {
                ...state,
                orglevels: action.payload,
                orglevel: {},
                loading: false,
                error: false
            }
        case ORG_LEVELS_BY_COMPANY_LIST_ERROR:
            return {
                ...state,
                orglevels: [],
                orglevel: {},
                loading: false,
                error: true
            }
        default:
            return state;
    }
}
