const X_TOKEN = 'x-token';

export const setToken = (tokenObj) => {
  sessionStorage.setItem(X_TOKEN, tokenObj);
  //sessionStorage.setItem("refresh_token", tokenObj.refresh_token);
}
export const getAccessToken = () =>{
    return sessionStorage.getItem(X_TOKEN);
}
export const getRefreshToken = () =>{
    return sessionStorage.getItem("refresh_token");
}
export const clearToken = () =>{
  sessionStorage.removeItem(X_TOKEN);
  //sessionStorage.removeItem("refresh_token");
}


