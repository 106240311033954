import {
    DEPARTMENTS_LIST,
    DEPARTMENTS_LIST_SUCCESS,
    DEPARTMENTS_LIST_ERROR,
    DEPARTMENTS_DDLIST,
    DEPARTMENTS_DDLIST_SUCCESS,
    DEPARTMENTS_DDLIST_ERROR,
    DEPARTMENTS_GETLIST,
    DEPARTMENTS_GETLIST_SUCCESS,
    DEPARTMENTS_GETLIST_ERROR,
} from '../types';

const initialState = {
    departments: [],
    department: {},
    loading: false,
    error: false
}

// eslint-disable-next-line import/no-anonymous-default-export
export default function (state = initialState, action) {
    switch (action.type) {
        case DEPARTMENTS_LIST:
            return {
                ...state,
                loading: true,
                department: {},
            }
        case DEPARTMENTS_LIST_SUCCESS:
            return {
                ...state,
                departments: action.payload,
                department: {},
                loading: false,
                error: false,
            }
        case DEPARTMENTS_LIST_ERROR:
            return {
                ...state,
                departments: [],
                department: {},
                loading: false,
                error: true,
            }
        case DEPARTMENTS_DDLIST:
            return {
                ...state,
                loading: true,
                department: {}
            }
        case DEPARTMENTS_DDLIST_SUCCESS:
            return {
                ...state,
                departments: action.payload,
                loading: false,
                error: false
            }
        case DEPARTMENTS_DDLIST_ERROR:
            return {
                ...state,
                departments: [],
                loading: false,
                error: true,
                department: {}
            }
        case DEPARTMENTS_GETLIST:
            return {
                ...state,
                loading: true,
                department: {}
            }
        case DEPARTMENTS_GETLIST_SUCCESS:
            return {
                ...state,
                departments: action.payload,
                loading: false,
                error: false
            }
        case DEPARTMENTS_GETLIST_ERROR:
            return {
                ...state,
                departments: [],
                loading: false,
                error: true,
                department: {}
            }
        default:
            return state;
    }
}
