import {
    SUPERVISORS_LIST,
    SUPERVISORS_LIST_SUCCESS,
    SUPERVISORS_LIST_ERROR,
    SUPERVISORS_DDLIST,
    SUPERVISORS_DDLIST_SUCCESS,
    SUPERVISORS_DDLIST_ERROR,
} from '../types';

const initialState = {
    supervisors: [],
    supervisor: {},
    loading: false,
    error: false
}

// eslint-disable-next-line import/no-anonymous-default-export
export default function(state = initialState, action){
    switch(action.type){
        case SUPERVISORS_LIST:
            return {
                ...state,
                loading: true,
                supervisor: {},
            }
        case SUPERVISORS_LIST_SUCCESS:
            return {
                ...state,
                supervisors: action.payload,
                supervisor: {},
                loading: false,
                error: false,
            }
        case SUPERVISORS_LIST_ERROR:
            return {
                ...state,
                supervisors: [],
                supervisor: {},
                loading: false,
                error: true,
            }
        case SUPERVISORS_DDLIST:
            return {
                ...state,
                loading: true,
                supervisor: {}
            }
        case SUPERVISORS_DDLIST_SUCCESS:
            return {
                ...state,
                supervisors: action.payload,
                loading: false,
                error: false
            }
        case SUPERVISORS_DDLIST_ERROR:
            return {
                ...state,
                supervisors: [],
                loading: false,
                error: true,
                supervisor: {}
            }
        default:
            return state;
    }
}
