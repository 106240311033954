import { EMAIL_LIST, EMAIL_LIST_SUCCESS, EMAIL_LIST_ERROR, EMAIL_GET, EMAIL_GET_ERROR, EMAIL_GET_SUCCESS } from '../types';

const initialState = {
     emails: [],
     email: {},
     loading: false,
     error: false
}

// eslint-disable-next-line import/no-anonymous-default-export
export default function (state = initialState, action) {
    switch(action.type) {
        case EMAIL_LIST:
            return {
                ...state,
                emails: [],
                email: {},
                loading: true,
            }
        case EMAIL_LIST_SUCCESS:
            return {
                ...state,
                emails: action.payload,
                loading: false,
                error: false,
                email: {},
            }
        case EMAIL_LIST_ERROR:
            return {
                ...state,
                emails: [],
                loading: false,
                error: true,
                email: {},
            }
        case EMAIL_GET:
            return {
                ...state,
                loading: true
            }
        case EMAIL_GET_SUCCESS:
            return {
                ...state,
                loading: false,
                error: false,
                email: action.payload,
                emails: []
            }
        case EMAIL_GET_ERROR:
            return {
                ...state,
                loading: false,
                error: true,
                email: {},
                emails: []
            }
        default:
            return state;
    }
}