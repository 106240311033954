import {
    SECTIONS_LIST,
    SECTIONS_LIST_SUCCESS,
    SECTIONS_LIST_ERROR,
    SECTIONS_DDLIST,
    SECTIONS_DDLIST_SUCCESS,
    SECTIONS_DDLIST_ERROR,
} from '../types';

const initialState = {
    sections: [],
    section: {},
    loading: false,
    error: false
}

// eslint-disable-next-line import/no-anonymous-default-export
export default function(state = initialState, action){
    switch(action.type){
        case SECTIONS_LIST:
            return {
                ...state,
                loading: true,
                section: {},
            }
        case SECTIONS_LIST_SUCCESS:
            return {
                ...state,
                sections: action.payload,
                section: {},
                loading: false,
                error: false,
            }
        case SECTIONS_LIST_ERROR:
            return {
                ...state,
                sections: [],
                section: {},
                loading: false,
                error: true,
            }
        case SECTIONS_DDLIST:
            return {
                ...state,
                loading: true,
                section: {}
            }
        case SECTIONS_DDLIST_SUCCESS:
            return {
                ...state,
                sections: action.payload,
                loading: false,
                error: false
            }
        case SECTIONS_DDLIST_ERROR:
            return {
                ...state,
                sections: [],
                loading: false,
                error: true,
                section: {}
            }
        default:
            return state;
    }
}
