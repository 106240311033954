/* eslint-disable import/no-anonymous-default-export */
import {
    USER_LIST,
    USER_LIST_SUCCESS,
    USER_LIST_ERROR,
    USER_DDLIST,
    USER_DDLIST_SUCCESS,
    USER_DDLIST_ERROR,
    USER_ALL_BY_COMPANY,
    USER_ALL_BY_COMPANY_SUCCESS,
    USER_ALL_BY_COMPANY_ERROR,
    USER_GET,
    USER_GET_SUCCESS,
    USER_GET_ERROR,
    USER_GET_SEARCH,
    USER_GET_SEARCH_SUCCESS,
    USER_GET_SEARCH_ERROR,
    USER_NEW,
    USER_NEW_SUCCESS,
    USER_NEW_ERROR,
    USER_EDIT,
    USER_EDIT_SUCCESS,
    USER_EDIT_ERROR,
    EDIT_OPEN_BAR
} from '../types';

const initialState = {
    users: [],
    user: {},
    loading: false,
    error: false,
    open_bar: true,
}

export default function(state = initialState, action){
    switch(action.type){
        case USER_LIST:
            return {
                ...state,
                loading: true,
                user: {},
            }
        case USER_LIST_SUCCESS:
            return {
                ...state,
                users: action.payload,
                user: {},
                loading: false,
                error: false,
            }
        case USER_LIST_ERROR:
            return {
                ...state,
                users: [],
                user: {},
                loading: false,
                error: true,
            }
        case USER_DDLIST:
            return {
                ...state,
                loading: true,
                user: {}
            }
        case USER_DDLIST_SUCCESS:
            return {
                ...state,
                usersList: action.payload,
                loading: false,
                error: false
            }
        case USER_DDLIST_ERROR:
            return {
                ...state,
                usersList: [],
                loading: false,
                error: true,
                user: {}
            }
        case USER_GET:
            return {
                ...state,
                loading: true
            }
        case USER_GET_SUCCESS:
            return {
                ...state,
                user: action.payload,
                loading: false,
                error: false
            }
        case USER_GET_ERROR:
            return {
                ...state,
                error: true,
                user: {}
            }
        case USER_GET_SEARCH:
            return {
                ...state,
                loading: true
            }
        case USER_GET_SEARCH_SUCCESS:
            switch (action.payload.field) {
                default:
                    return {
                        ...state,
                        user: action.payload,
                        loading: false,
                        error: false
                    }
            }

        case USER_GET_SEARCH_ERROR:
            switch (action.payload.field) {
                default:
                    return {
                        ...state,
                        error: true,
                        user: {}
                    }
            }
        case USER_ALL_BY_COMPANY:
            return {
                ...state,
                loading: true,
                user: {},
            }
        case USER_ALL_BY_COMPANY_SUCCESS:
            return {
                ...state,
                users: action.payload,
                user: {},
                loading: false,
                error: false,
            }
        case USER_ALL_BY_COMPANY_ERROR:
            return {
                ...state,
                users: [],
                user: {},
                loading: false,
                error: true,
            }
        case USER_NEW:
            return {
                ...state
            }
        case USER_NEW_SUCCESS:
            return {
                ...state,
                error: false,
                users: [...state.users, action.payload]
            }
        case USER_NEW_ERROR:
            return {
                ...state,
                error: true
            }
        case USER_EDIT:
            return {
                ...state,
            }
        case USER_EDIT_SUCCESS:
            return {
                ...state,
                error: false,
                user: action.payload,
                users: state.users.map(user => user.id === action.payload.id ? user = action.payload : user),
            }
        case USER_EDIT_ERROR:
            return {
                ...state,
                error: true
            }
        case EDIT_OPEN_BAR:
            return {
                ...state,
                open_bar: action.payload
            }
        default:
            return state;
    }
}
