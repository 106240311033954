/* eslint-disable import/no-anonymous-default-export */
import {
    CLIENT_LIST,
    CLIENT_LIST_SUCCESS,
    CLIENT_LIST_ERROR,
    CLIENT_DDLIST,
    CLIENT_DDLIST_SUCCESS,
    CLIENT_DDLIST_ERROR,
    CLIENT_GET,
    CLIENT_GET_SUCCESS,
    CLIENT_GET_ERROR,
    CLIENT_GET_SEARCH,
    CLIENT_GET_SEARCH_SUCCESS,
    CLIENT_GET_SEARCH_ERROR,
    CLIENT_NEW,
    CLIENT_NEW_SUCCESS,
    CLIENT_NEW_ERROR,
    CLIENT_EDIT,
    CLIENT_EDIT_SUCCESS,
    CLIENT_EDIT_ERROR
} from '../types';

const initialState = {
    clients: [],
    client: {},
    loading: false,
    error: false
}

export default function(state = initialState, action){
    switch(action.type){
        case CLIENT_LIST:
            return {
                ...state,
                loading: true,
                client: {},
            }
        case CLIENT_LIST_SUCCESS:
            return {
                ...state,
                clients: action.payload,
                client: {},
                loading: false,
                error: false,
            }
        case CLIENT_LIST_ERROR:
            return {
                ...state,
                clients: [],
                client: {},
                loading: false,
                error: true,
            }
        case CLIENT_DDLIST:
            return {
                ...state,
                loading: true,
                client: {}
            }
        case CLIENT_DDLIST_SUCCESS:
            return {
                ...state,
                clients: action.payload,
                loading: false,
                error: false
            }
        case CLIENT_DDLIST_ERROR:
            return {
                ...state,
                clients: [],
                loading: false,
                error: true,
                client: {}
            }
        case CLIENT_GET:
            return {
                ...state,
                loading: true
            }
        case CLIENT_GET_SUCCESS:
            return {
                ...state,
                client: action.payload,
                loading: false,
                error: false
            }
        case CLIENT_GET_ERROR:
            return {
                ...state,
                error: true,
                client: {}
            }
        case CLIENT_GET_SEARCH:
            return {
                ...state,
                loading: true
            }
        case CLIENT_GET_SEARCH_SUCCESS:
            switch (action.payload.field) {
                default:
                    return {
                        ...state,
                        client: action.payload,
                        loading: false,
                        error: false
                    }
            }

        case CLIENT_GET_SEARCH_ERROR:
            switch (action.payload.field) {
                default:
                    return {
                        ...state,
                        error: true,
                        client: {}
                    }
            }
        case CLIENT_NEW:
            return {
                ...state
            }
        case CLIENT_NEW_SUCCESS:
            return {
                ...state,
                error: false,
                clients: [...state.clients, action.payload]
            }
        case CLIENT_NEW_ERROR:
            return {
                ...state,
                error: true
            }
        case CLIENT_EDIT:
            return {
                ...state,
            }
        case CLIENT_EDIT_SUCCESS:
            return {
                ...state,
                error: false,
                client: action.payload,
                clients: state.clients.map(client => client.id === action.payload.id ? client = action.payload : client),
            }
        case CLIENT_EDIT_ERROR:
            return {
                ...state,
                error: true
            }
        default:
            return state;
    }
}
