// Constantes de Areas
export const AREAS_LIST = 'AREAS_LIST';
export const AREAS_LIST_SUCCESS = 'AREAS_LIST_SUCCESS';
export const AREAS_LIST_ERROR = 'AREAS_LIST_ERROR';
export const AREAS_DDLIST = 'AREAS_DDLIST';
export const AREAS_DDLIST_SUCCESS = 'AREAS_DDLIST_SUCCESS';
export const AREAS_DDLIST_ERROR = 'AREAS_DDLIST_ERROR';

// Constantes de Charges (Cargos)
export const CHARGES_LIST = 'CHARGES_LIST';
export const CHARGES_LIST_SUCCESS = 'CHARGES_LIST_SUCCESS';
export const CHARGES_LIST_ERROR = 'CHARGES_LIST_ERROR';
export const CHARGES_DDLIST = 'CHARGES_DDLIST';
export const CHARGES_DDLIST_SUCCESS = 'CHARGES_DDLIST_SUCCESS';
export const CHARGES_DDLIST_ERROR = 'CHARGES_DDLIST_ERROR';
export const CHARGES_GETLIST = 'CHARGES_GETLIST';
export const CHARGES_GETLIST_SUCCESS = 'CHARGES_GETLIST_SUCCESS';
export const CHARGES_GETLIST_ERROR = 'CHARGES_GETLIST_ERROR';

// Constantes de ChargeLevel (Nivel de Cargos)
export const CHARGESLEVEL_LIST = 'CHARGESLEVEL_LIST';
export const CHARGESLEVEL_LIST_SUCCESS = 'CHARGESLEVEL_LIST_SUCCESS';
export const CHARGESLEVEL_LIST_ERROR = 'CHARGESLEVEL_LIST_ERROR';
export const CHARGESLEVEL_DDLIST = 'CHARGESLEVEL_DDLIST';
export const CHARGESLEVEL_DDLIST_SUCCESS = 'CHARGESLEVEL_DDLIST_SUCCESS';
export const CHARGESLEVEL_DDLIST_ERROR = 'CHARGESLEVEL_DDLIST_ERROR';

// Constantes de Supervisor
export const SUPERVISORS_LIST = 'SUPERVISORS_LIST';
export const SUPERVISORS_LIST_SUCCESS = 'SUPERVISORS_LIST_SUCCESS';
export const SUPERVISORS_LIST_ERROR = 'SUPERVISORS_LIST_ERROR';
export const SUPERVISORS_DDLIST = 'SUPERVISORS_DDLIST';
export const SUPERVISORS_DDLIST_SUCCESS = 'SUPERVISORS_DDLIST_SUCCESS';
export const SUPERVISORS_DDLIST_ERROR = 'SUPERVISORS_DDLIST_ERROR';

// Constantes de Collaborators
export const COLLABORATORS_LIST = 'COLLABORATORS_LIST';
export const COLLABORATORS_LIST_SUCCESS = 'COLLABORATORS_LIST_SUCCESS';
export const COLLABORATORS_LIST_ERROR = 'COLLABORATORS_LIST_ERROR';
export const COLLABORATORS_DDLIST = 'COLLABORATORS_DDLIST';
export const COLLABORATORS_DDLIST_SUCCESS = 'COLLABORATORS_DDLIST_SUCCESS';
export const COLLABORATORS_DDLIST_ERROR = 'COLLABORATORS_DDLIST_ERROR';

// Constantes de Categorias
export const CATEGORIES_LIST                = 'CATEGORIES_LIST';
export const CATEGORIES_LIST_SUCCESS        = 'CATEGORIES_LIST_SUCCESS';
export const CATEGORIES_LIST_ERROR          = 'CATEGORIES_LIST_ERROR';
export const CATEGORIES_DDLIST              = 'CATEGORIES_DDLIST';
export const CATEGORIES_DDLIST_SUCCESS      = 'CATEGORIES_DDLIST_SUCCESS';
export const CATEGORIES_DDLIST_ERROR        = 'CATEGORIES_DDLIST_ERROR';
export const CATEGORIES_GET                 = 'CATEGORIES_GET';
export const CATEGORIES_GET_SUCCESS         = 'CATEGORIES_GET_SUCCESS';
export const CATEGORIES_GET_ERROR           = 'CATEGORIES_GET_ERROR';
export const CATEGORIES_GET_SEARCH          = 'CATEGORIES_GET_SEARCH';
export const CATEGORIES_GET_SEARCH_SUCCESS  = 'CATEGORIES_GET_SEARCH_SUCCESS';
export const CATEGORIES_GET_SEARCH_ERROR    = 'CATEGORIES_GET_SEARCH_ERROR';
export const CATEGORIES_NEW                 = 'CATEGORIES_NEW';
export const CATEGORIES_NEW_SUCCESS         = 'CATEGORIES_NEW_SUCCESS';
export const CATEGORIES_NEW_ERROR           = 'CATEGORIES_NEW_ERROR';
export const CATEGORIES_NEW_RECORD          = 'CATEGORIES_NEW_RECORD';
export const CATEGORIES_EDIT                = 'CATEGORIES_EDIT';
export const CATEGORIES_EDIT_SUCCESS        = 'CATEGORIES_EDIT_SUCCESS';
export const CATEGORIES_EDIT_ERROR          = 'CATEGORIES_EDIT_ERROR';

// Constantes de Configuracion de Parametros para Reporte de Competencias Organizacionales
export const CONFIGPARAMETERS_LIST                = 'CONFIGPARAMETERS_LIST';
export const CONFIGPARAMETERS_LIST_SUCCESS        = 'CONFIGPARAMETERS_LIST_SUCCESS';
export const CONFIGPARAMETERS_LIST_ERROR          = 'CONFIGPARAMETERS_LIST_ERROR';
export const CONFIGPARAMETERS_DDLIST              = 'CONFIGPARAMETERS_DDLIST';
export const CONFIGPARAMETERS_DDLIST_SUCCESS      = 'CONFIGPARAMETERS_DDLIST_SUCCESS';
export const CONFIGPARAMETERS_DDLIST_ERROR        = 'CONFIGPARAMETERS_DDLIST_ERROR';
export const CONFIGPARAMETERS_GET                 = 'CONFIGPARAMETERS_GET';
export const CONFIGPARAMETERS_GET_SUCCESS         = 'CONFIGPARAMETERS_GET_SUCCESS';
export const CONFIGPARAMETERS_GET_ERROR           = 'CONFIGPARAMETERS_GET_ERROR';
export const CONFIGPARAMETERS_GET_SEARCH          = 'CONFIGPARAMETERS_GET_SEARCH';
export const CONFIGPARAMETERS_GET_SEARCH_SUCCESS  = 'CONFIGPARAMETERS_GET_SEARCH_SUCCESS';
export const CONFIGPARAMETERS_GET_SEARCH_ERROR    = 'CONFIGPARAMETERS_GET_SEARCH_ERROR';
export const CONFIGPARAMETERS_NEW                 = 'CONFIGPARAMETERS_NEW';
export const CONFIGPARAMETERS_NEW_SUCCESS         = 'CONFIGPARAMETERS_NEW_SUCCESS';
export const CONFIGPARAMETERS_NEW_ERROR           = 'CONFIGPARAMETERS_NEW_ERROR';
export const CONFIGPARAMETERS_NEW_RECORD          = 'CONFIGPARAMETERS_NEW_RECORD';
export const CONFIGPARAMETERS_EDIT                = 'CONFIGPARAMETERS_EDIT';
export const CONFIGPARAMETERS_EDIT_SUCCESS        = 'CONFIGPARAMETERS_EDIT_SUCCESS';
export const CONFIGPARAMETERS_EDIT_ERROR          = 'CONFIGPARAMETERS_EDIT_ERROR';

// Constantes de Client
export const CLIENT_LIST = 'CLIENT_LIST';
export const CLIENT_LIST_SUCCESS = 'CLIENT_LIST_SUCCESS';
export const CLIENT_LIST_ERROR = 'CLIENT_LIST_ERROR';
export const CLIENT_DDLIST = 'CLIENT_DDLIST';
export const CLIENT_DDLIST_SUCCESS = 'CLIENT_DDLIST_SUCCESS';
export const CLIENT_DDLIST_ERROR = 'CLIENT_DDLIST_ERROR';
export const CLIENT_GET = 'CLIENT_GET';
export const CLIENT_GET_SUCCESS = 'CLIENT_GET_SUCCESS';
export const CLIENT_GET_ERROR = 'CLIENT_GET_ERROR';
export const CLIENT_GET_SEARCH = 'CLIENT_GET_SEARCH';
export const CLIENT_GET_SEARCH_SUCCESS = 'CLIENT_GET_SEARCH_SUCCESS';
export const CLIENT_GET_SEARCH_ERROR = 'CLIENT_GET_SEARCH_ERROR';
export const CLIENT_NEW  = 'CLIENT_NEW';
export const CLIENT_NEW_SUCCESS = 'CLIENT_NEW_SUCCESS';
export const CLIENT_NEW_ERROR = 'CLIENT_NEW_ERROR';
export const CLIENT_NEW_RECORD = 'CLIENT_NEW_RECORD';
export const CLIENT_EDIT = 'CLIENT_EDIT';
export const CLIENT_EDIT_SUCCESS = 'CLIENT_EDIT_SUCCESS';
export const CLIENT_EDIT_ERROR = 'CLIENT_EDIT_ERROR';

// Constantes de Companias
export const COMPANY_LIST = 'COMPANY_LIST';
export const COMPANY_LIST_SUCCESS = 'COMPANY_LIST_SUCCESS';
export const COMPANY_LIST_ERROR = 'COMPANY_LIST_ERROR';
export const COMPANY_DDLIST = 'COMPANY_DDLIST';
export const COMPANY_DDLIST_SUCCESS = 'COMPANY_DDLIST_SUCCESS';
export const COMPANY_DDLIST_ERROR = 'COMPANY_DDLIST_ERROR';

// Constantes de Departmen (Departamentos)
export const DEPARTMENTS_LIST = 'DEPARTMENTS_LIST';
export const DEPARTMENTS_LIST_SUCCESS = 'DEPARTMENTS_LIST_SUCCESS';
export const DEPARTMENTS_LIST_ERROR = 'DEPARTMENTS_LIST_ERROR';
export const DEPARTMENTS_DDLIST = 'DEPARTMENTS_DDLIST';
export const DEPARTMENTS_DDLIST_SUCCESS = 'DEPARTMENTS_DDLIST_SUCCESS';
export const DEPARTMENTS_DDLIST_ERROR = 'DEPARTMENTS_DDLIST_ERROR';
export const DEPARTMENTS_GETLIST = 'DEPARTMENTS_GETLIST';
export const DEPARTMENTS_GETLIST_SUCCESS = 'DEPARTMENTS_GETLIST_SUCCESS';
export const DEPARTMENTS_GETLIST_ERROR = 'DEPARTMENTS_GETLIST_ERROR';

// Constantes de Dominios
export const DOMAINS_LIST               = 'DOMAINS_LIST';
export const DOMAINS_LIST_SUCCESS       = 'DOMAINS_LIST_SUCCESS';
export const DOMAINS_LIST_ERROR         = 'DOMAINS_LIST_ERROR';
export const DOMAINS_DDLIST             = 'DOMAINS_DDLIST';
export const DOMAINS_DDLIST_SUCCESS     = 'DOMAINS_DDLIST_SUCCESS';
export const DOMAINS_DDLIST_ERROR       = 'DOMAINS_DDLIST_ERROR';
export const DOMAINS_GET                = 'DOMAINS_GET';
export const DOMAINS_GET_SUCCESS        = 'DOMAINS_GET_SUCCESS';
export const DOMAINS_GET_ERROR          = 'DOMAINS_GET_ERROR';
// export const DOMAINS_GET_SEARCH         = 'DOMAINS_GET_SEARCH';
// export const DOMAINS_GET_SEARCH_SUCCESS = 'DOMAINS_GET_SEARCH_SUCCESS';
// export const DOMAINS_GET_SEARCH_ERROR   = 'DOMAINS_GET_SEARCH_ERROR';
export const DOMAINS_NEW                = 'DOMAINS_NEW';
export const DOMAINS_NEW_SUCCESS        = 'DOMAINS_NEW_SUCCESS';
export const DOMAINS_NEW_ERROR          = 'DOMAINS_NEW_ERROR';
export const DOMAINS_NEW_RECORD         = 'DOMAINS_NEW_RECORD';
export const DOMAINS_EDIT               = 'DOMAINS_EDIT';
export const DOMAINS_EDIT_SUCCESS       = 'DOMAINS_EDIT_SUCCESS';
export const DOMAINS_EDIT_ERROR         = 'DOMAINS_EDIT_ERROR';

// Constantes de MasterDatas
export const MASTERDATAS_LIST = 'MASTERDATAS_LIST';
export const MASTERDATAS_LIST_SUCCESS = 'MASTERDATAS_LIST_SUCCESS';
export const MASTERDATAS_LIST_ERROR = 'MASTERDATAS_LIST_ERROR';
export const MASTERDATAS_LIST_ALL   = 'MASTERDATAS_LIST_ALL';
export const MASTERDATAS_LIST_ALL_SUCCESS = 'MASTERDATAS_LIST_ALL_SUCCESS';
export const MASTERDATAS_LIST_ALL_ERROR = 'MASTERDATAS_LIST_ALL_ERROR';
export const MASTERDATAS_DDLIST = 'MASTERDATAS_DDLIST';
export const MASTERDATAS_DDLIST_SUCCESS = 'MASTERDATAS_DDLIST_SUCCESS';
export const MASTERDATAS_DDLIST_ERROR = 'MASTERDATAS_DDLIST_ERROR';
export const MASTERDATAS_GET = 'MASTERDATAS_GET';
export const MASTERDATAS_GET_SUCCESS = 'MASTERDATAS_GET_SUCCESS';
export const MASTERDATAS_GET_ERROR = 'MASTERDATAS_GET_ERROR';
export const MASTERDATAS_GET_SEARCH = 'MASTERDATAS_GET_SEARCH';
export const MASTERDATAS_GET_SEARCH_SUCCESS = 'MASTERDATAS_GET_SEARCH_SUCCESS';
export const MASTERDATAS_GET_SEARCH_ERROR = 'MASTERDATAS_GET_SEARCH_ERROR';
export const MASTERDATAS_NEW  = 'MASTERDATAS_NEW';
export const MASTERDATAS_NEW_SUCCESS = 'MASTERDATAS_NEW_SUCCESS';
export const MASTERDATAS_NEW_ERROR = 'MASTERDATAS_NEW_ERROR';
export const MASTERDATAS_NEW_RECORD = 'MASTERDATAS_NEW_RECORD';
export const MASTERDATAS_EDIT = 'MASTERDATAS_EDIT';
export const MASTERDATAS_EDIT_SUCCESS = 'MASTERDATAS_EDIT_SUCCESS';
export const MASTERDATAS_EDIT_ERROR = 'MASTERDATAS_EDIT_ERROR';


// Constantes de Configuracion de MaxDiff
export const MAXDIFFCONFIG_GET             = 'MAXDIFFCONFIG_GET';
export const MAXDIFFCONFIG_GET_SUCCESS     = 'MAXDIFFCONFIG_GET_SUCCESS';
export const MAXDIFFCONFIG_GET_ERROR       = 'MAXDIFFCONFIG_GET_ERROR';
export const MAXDIFFCONFIG_NEW             = 'MAXDIFFCONFIG_NEW';
export const MAXDIFFCONFIG_NEW_SUCCESS     = 'MAXDIFFCONFIG_NEW_SUCCESS';
export const MAXDIFFCONFIG_NEW_ERROR       = 'MAXDIFFCONFIG_NEW_ERROR';
export const MAXDIFFCONFIG_NEW_RECORD      = 'MAXDIFFCONFIG_NEW_RECORD';
export const MAXDIFFCONFIG_EDIT            = 'MAXDIFFCONFIG_EDIT';
export const MAXDIFFCONFIG_EDIT_SUCCESS    = 'MAXDIFFCONFIG_EDIT_SUCCESS';
export const MAXDIFFCONFIG_EDIT_ERROR      = 'MAXDIFFCONFIG_EDIT_ERROR';
export const MAXDIFFCONFIG_BAREMO_GET      = 'MAXDIFFCONFIG_BAREMO_GET';
export const MAXDIFFCONFIG_BAREMO_GET_SUCCESS      = 'MAXDIFFCONFIG_BAREMO_GET_SUCCESS';
export const MAXDIFFCONFIG_BAREMO_GET_ERROR      = 'MAXDIFFCONFIG_BAREMO_GET_ERROR';



// Constantes de Operative Unit (Unidad Operativa)
export const OPERATIVEUNITS_LIST = 'OPERATIVEUNITS_LIST';
export const OPERATIVEUNITS_LIST_SUCCESS = 'OPERATIVEUNITS_LIST_SUCCESS';
export const OPERATIVEUNITS_LIST_ERROR = 'OPERATIVEUNITS_LIST_ERROR';
export const OPERATIVEUNITS_DDLIST = 'OPERATIVEUNITS_DDLIST';
export const OPERATIVEUNITS_DDLIST_SUCCESS = 'OPERATIVEUNITS_DDLIST_SUCCESS';
export const OPERATIVEUNITS_DDLIST_ERROR = 'OPERATIVEUNITS_DDLIST_ERROR';

// Constantes de Parameters
export const PARAMETERS_LIST = 'PARAMETERS_LIST';
export const PARAMETERS_LIST_SUCCESS = 'PARAMETERS_LIST_SUCCESS';
export const PARAMETERS_LIST_ERROR = 'PARAMETERS_LIST_ERROR';
export const PARAMETERS_DDLIST = 'PARAMETERS_DDLIST';
export const PARAMETERS_DDLIST_SUCCESS = 'PARAMETERS_DDLIST_SUCCESS';
export const PARAMETERS_DDLIST_ERROR = 'PARAMETERS_DDLIST_ERROR';
export const PARAMETERS_GET = 'PARAMETERS_GET';
export const PARAMETERS_GET_SUCCESS = 'PARAMETERS_GET_SUCCESS';
export const PARAMETERS_GET_ERROR = 'PARAMETERS_GET_ERROR';
export const PARAMETERS_GET_SEARCH = 'PARAMETERS_GET_SEARCH';
export const PARAMETERS_GET_SEARCH_SUCCESS = 'PARAMETERS_GET_SEARCH_SUCCESS';
export const PARAMETERS_GET_SEARCH_ERROR = 'PARAMETERS_GET_SEARCH_ERROR';
export const PARAMETERS_NEW  = 'PARAMETERS_NEW';
export const PARAMETERS_NEW_SUCCESS = 'PARAMETERS_NEW_SUCCESS';
export const PARAMETERS_NEW_ERROR = 'PARAMETERS_NEW_ERROR';
export const PARAMETERS_NEW_RECORD = 'PARAMETERS_NEW_RECORD';
export const PARAMETERS_EDIT = 'PARAMETERS_EDIT';
export const PARAMETERS_EDIT_SUCCESS = 'PARAMETERS_EDIT_SUCCESS';
export const PARAMETERS_EDIT_ERROR = 'PARAMETERS_EDIT_ERROR';

// Constantes de Roles
export const ROLES_LIST = 'ROLES_LIST';
export const ROLES_LIST_SUCCESS = 'ROLES_LIST_SUCCESS';
export const ROLES_LIST_ERROR = 'ROLES_LIST_ERROR';
export const ROLES_DDLIST = 'ROLES_DDLIST';
export const ROLES_DDLIST_SUCCESS = 'ROLES_DDLIST_SUCCESS';
export const ROLES_DDLIST_ERROR = 'ROLES_DDLIST_ERROR';
export const ROLES_GET = 'ROLES_GET';
export const ROLES_GET_SUCCESS = 'ROLES_GET_SUCCESS';
export const ROLES_GET_ERROR = 'ROLES_GET_ERROR';
export const ROLES_GET_SEARCH = 'ROLES_GET_SEARCH';
export const ROLES_GET_SEARCH_SUCCESS = 'ROLES_GET_SEARCH_SUCCESS';
export const ROLES_GET_SEARCH_ERROR = 'ROLES_GET_SEARCH_ERROR';
export const ROLES_NEW  = 'ROLES_NEW';
export const ROLES_NEW_SUCCESS = 'ROLES_NEW_SUCCESS';
export const ROLES_NEW_ERROR = 'ROLES_NEW_ERROR';
export const ROLES_NEW_RECORD = 'ROLES_NEW_RECORD';
export const ROLES_EDIT = 'ROLES_EDIT';
export const ROLES_EDIT_SUCCESS = 'ROLES_EDIT_SUCCESS';
export const ROLES_EDIT_ERROR = 'ROLES_EDIT_ERROR';

// Constantes de Sections (Secciones)
export const SECTIONS_LIST = 'SECTIONS_LIST';
export const SECTIONS_LIST_SUCCESS = 'SECTIONS_LIST_SUCCESS';
export const SECTIONS_LIST_ERROR = 'SECTIONS_LIST_ERROR';
export const SECTIONS_DDLIST = 'SECTIONS_DDLIST';
export const SECTIONS_DDLIST_SUCCESS = 'SECTIONS_DDLIST_SUCCESS';
export const SECTIONS_DDLIST_ERROR = 'SECTIONS_DDLIST_ERROR';

export const SURVEY_LIST          = 'SURVEY_LIST';
export const SURVEY_LIST_SUCCESS  = 'SURVEY_LIST_SUCCESS';
export const SURVEY_LIST_ERROR    = 'SURVEY_LIST_ERROR';

// Constantes de User
export const USER_LIST = 'USER_LIST';
export const USER_LIST_SUCCESS = 'USER_LIST_SUCCESS';
export const USER_LIST_ERROR = 'USER_LIST_ERROR';
export const USER_DDLIST = 'USER_DDLIST';
export const USER_DDLIST_SUCCESS = 'USER_DDLIST_SUCCESS';
export const USER_DDLIST_ERROR = 'USER_DDLIST_ERROR';
export const USER_ALL_BY_COMPANY = 'USER_ALL_BY_COMPANY';
export const USER_ALL_BY_COMPANY_SUCCESS = 'USER_ALL_BY_COMPANY_SUCCESS';
export const USER_ALL_BY_COMPANY_ERROR = 'USER_ALL_BY_COMPANY_ERROR';
export const USER_GET = 'USER_GET';
export const USER_GET_SUCCESS = 'USER_GET_SUCCESS';
export const USER_GET_ERROR = 'USER_GET_ERROR';
export const USER_GET_SEARCH = 'USER_GET_SEARCH';
export const USER_GET_SEARCH_SUCCESS = 'USER_GET_SEARCH_SUCCESS';
export const USER_GET_SEARCH_ERROR = 'USER_GET_SEARCH_ERROR';
export const USER_NEW  = 'USER_NEW';
export const USER_NEW_SUCCESS = 'USER_NEW_SUCCESS';
export const USER_NEW_ERROR = 'USER_NEW_ERROR';
export const USER_NEW_RECORD = 'USER_NEW_RECORD';
export const USER_EDIT = 'USER_EDIT';
export const USER_EDIT_SUCCESS = 'USER_EDIT_SUCCESS';
export const USER_EDIT_ERROR = 'USER_EDIT_ERROR';
export const EDIT_OPEN_BAR = 'EDIT_OPEN_BAR';

//Constantes de reportes
export const REPORTS_LIST = 'REPORTS_LIST';
export const REPORTS_LIST_SUCCESS = 'REPORTS_LIST_SUCCESS';
export const REPORTS_LIST_ERROR = 'REPORTS_LIST_ERROR';
export const REPORTS_LIST_NO_PROCESS = 'REPORTS_LIST_NO_PROCESS';
export const REPORTS_LIST_NO_PROCESS_SUCCESS = 'REPORTS_LIST_NO_PROCESS_SUCCESS';
export const REPORTS_LIST_NO_PROCESS_ERROR = 'REPORTS_LIST_NO_PROCESS_ERROR';

// Constantes de Roles
export const COMPETENCES_LIST = 'COMPETENCES_LIST';
export const COMPETENCES_LIST_SUCCESS = 'COMPETENCES_LIST_SUCCESS';
export const COMPETENCES_LIST_ERROR = 'COMPETENCES_LIST_ERROR';
export const COMPETENCES_DDLIST = 'COMPETENCES_DDLIST';
export const COMPETENCES_DDLIST_SUCCESS = 'COMPETENCES_DDLIST_SUCCESS';
export const COMPETENCES_DDLIST_ERROR = 'COMPETENCES_DDLIST_ERROR';
export const COMPETENCES_GET = 'COMPETENCES_GET';
export const COMPETENCES_GET_SUCCESS = 'COMPETENCES_GET_SUCCESS';
export const COMPETENCES_GET_ERROR = 'COMPETENCES_GET_ERROR';
export const COMPETENCES_GET_SEARCH = 'COMPETENCES_GET_SEARCH';
export const COMPETENCES_GET_SEARCH_SUCCESS = 'COMPETENCES_GET_SEARCH_SUCCESS';
export const COMPETENCES_GET_SEARCH_ERROR = 'COMPETENCES_GET_SEARCH_ERROR';
export const COMPETENCES_NEW  = 'COMPETENCES_NEW';
export const COMPETENCES_NEW_SUCCESS = 'COMPETENCES_NEW_SUCCESS';
export const COMPETENCES_NEW_ERROR = 'COMPETENCES_NEW_ERROR';
export const COMPETENCES_NEW_RECORD = 'COMPETENCES_NEW_RECORD';
export const COMPETENCES_EDIT = 'COMPETENCES_EDIT';
export const COMPETENCES_EDIT_SUCCESS = 'COMPETENCES_EDIT_SUCCESS';
export const COMPETENCES_EDIT_ERROR = 'COMPETENCES_EDIT_ERROR';
export const COMPETENCES_BY_TYPE_DDLIST = 'COMPETENCES_BY_TYPE_DDLIST';
export const COMPETENCES_BY_TYPE_DDLIST_SUCCESS = 'COMPETENCES_BY_TYPE_DDLIST_SUCCESS';
export const COMPETENCES_BY_TYPE_DDLIST_ERROR = 'COMPETENCES_BY_TYPE_DDLIST_ERROR';


//Constantes de reportes
export const ORG_LEVELS_LIST = 'ORG_LEVELS_LIST';
export const ORG_LEVELS_LIST_SUCCESS = 'ORG_LEVELS_LIST_SUCCESS';
export const ORG_LEVELS_LIST_ERROR = 'ORG_LEVELS_LIST_ERROR';
export const ORG_LEVELS_BY_COMPANY_LIST = 'ORG_LEVELS_BY_COMPANY_LIST';
export const ORG_LEVELS_BY_COMPANY_LIST_SUCCESS = 'ORG_LEVELS_BY_COMPANY_LIST_SUCCESS';
export const ORG_LEVELS_BY_COMPANY_LIST_ERROR = 'ORG_LEVELS_BY_COMPANY_LIST_ERROR';

// Constantes de Niveles de Dominio
export const DOMAINLEVELS_LIST = 'DOMAINLEVELS_LIST';
export const DOMAINLEVELS_LIST_SUCCESS = 'DOMAINLEVELS_LIST_SUCCESS';
export const DOMAINLEVELS_LIST_ERROR = 'DOMAINLEVELS_LIST_ERROR';
export const DOMAINLEVELS_DDLIST = 'DOMAINLEVELS_DDLIST';
export const DOMAINLEVELS_DDLIST_SUCCESS = 'DOMAINLEVELS_DDLIST_SUCCESS';
export const DOMAINLEVELS_DDLIST_ERROR = 'DOMAINLEVELS_DDLIST_ERROR';
export const DOMAINLEVELS_GET_ONE = 'DOMAINLEVELS_GET_ONE';
export const DOMAINLEVELS_GET_ONE_SUCCESS = 'DOMAINLEVELS_GET_ONE_SUCCESS';
export const DOMAINLEVELS_GET_ONE_ERROR = 'DOMAINLEVELS_GET_ONE_ERROR';

// Constants Email Maintainer
export const EMAIL_LIST = 'EMAIL_LIST';
export const EMAIL_LIST_SUCCESS = 'EMAIL_LIST_SUCCESS';
export const EMAIL_LIST_ERROR = 'EMAIL_LIST_ERROR';
export const EMAIL_GET = 'EMAIL_GET';
export const EMAIL_GET_SUCCESS = 'EMAIL_GET_SUCCESS';
export const EMAIL_GET_ERROR = 'EMAIL_GET_ERROR';