import {
    REPORTS_LIST_NO_PROCESS,
    REPORTS_LIST_NO_PROCESS_SUCCESS,
    REPORTS_LIST_NO_PROCESS_ERROR,
} from '../types';

const initialState = {
    reports: [],
    report: {},
    loading: false,
    error: false
}

// eslint-disable-next-line import/no-anonymous-default-export
export default function(state = initialState, action){
    switch(action.type){
        case REPORTS_LIST_NO_PROCESS:
            return {
                ...state,
                loading: true,
                report: {},
            }
        case REPORTS_LIST_NO_PROCESS_SUCCESS:
            return {
                ...state,
                reports: action.payload,
                report: {},
                loading: false,
                error: false,
            }
        case REPORTS_LIST_NO_PROCESS_ERROR:
            return {
                ...state,
                reports: [],
                report: {},
                loading: false,
                error: true,
            }           
        default:
            return state;
    }
}
