import {
    SURVEY_LIST,
    SURVEY_LIST_SUCCESS,
    SURVEY_LIST_ERROR     
} from '../types';

const initialState = {
    surveys: [],
    survey: {},
    loading: false,
    error: false
}

// eslint-disable-next-line import/no-anonymous-default-export
export default function(state = initialState, action){
    switch(action.type){
        case SURVEY_LIST:
            return {
                ...state,
                loading: true,
                survey: {},
            }
        case SURVEY_LIST_SUCCESS:
            return {
                ...state,
                surveys: action.payload,
                survey: {},
                loading: false,
                error: false,
            }
        case SURVEY_LIST_ERROR:
            return {
                ...state,
                surveys: [],
                survey: {},
                loading: false,
                error: true,
            }           
        default:
            return state;
    }
}
