/* eslint-disable import/no-anonymous-default-export */
import {
    COMPETENCES_LIST,
    COMPETENCES_LIST_SUCCESS,
    COMPETENCES_LIST_ERROR,
    COMPETENCES_DDLIST,
    COMPETENCES_DDLIST_SUCCESS,
    COMPETENCES_DDLIST_ERROR,
    COMPETENCES_GET,
    COMPETENCES_GET_SUCCESS,
    COMPETENCES_GET_ERROR,
    COMPETENCES_GET_SEARCH,
    COMPETENCES_GET_SEARCH_SUCCESS,
    COMPETENCES_GET_SEARCH_ERROR,
    COMPETENCES_NEW,
    COMPETENCES_NEW_SUCCESS,
    COMPETENCES_NEW_ERROR,
    COMPETENCES_EDIT,
    COMPETENCES_EDIT_SUCCESS,
    COMPETENCES_EDIT_ERROR,
    COMPETENCES_BY_TYPE_DDLIST,
    COMPETENCES_BY_TYPE_DDLIST_SUCCESS,
    COMPETENCES_BY_TYPE_DDLIST_ERROR,
} from '../types';

const initialState = {
    competences: [],
    competence: {},
    loading: false,
    error: false
}

export default function(state = initialState, action){
    switch(action.type){
        case COMPETENCES_LIST:
            return {
                ...state,
                loading: true,
                competence: {},
            }
        case COMPETENCES_LIST_SUCCESS:
            return {
                ...state,
                competences: action.payload,
                competence: {},
                loading: false,
                error: false,
            }
        case COMPETENCES_LIST_ERROR:
            return {
                ...state,
                competences: [],
                competence: {},
                loading: false,
                error: true,
            }
        case COMPETENCES_DDLIST:
            return {
                ...state,
                loading: true,
                competence: {}
            }
        case COMPETENCES_DDLIST_SUCCESS:
            return {
                ...state,
                competencesList: action.payload,
                loading: false,
                error: false
            }
        case COMPETENCES_DDLIST_ERROR:
            return {
                ...state,
                competencesList: [],
                loading: false,
                error: true,
                competence: {}
            }
        case COMPETENCES_GET:
            return {
                ...state,
                loading: true
            }
        case COMPETENCES_GET_SUCCESS:
            return {
                ...state,
                competence: action.payload,
                loading: false,
                error: false
            }
        case COMPETENCES_GET_ERROR:
            return {
                ...state,
                error: true,
                competence: {}
            }
        case COMPETENCES_GET_SEARCH:
            return {
                ...state,
                loading: true
            }
        case COMPETENCES_GET_SEARCH_SUCCESS:
            switch (action.payload.field) {
                default:
                    return {
                        ...state,
                        competence: action.payload,
                        loading: false,
                        error: false
                    }
            }

        case COMPETENCES_GET_SEARCH_ERROR:
            switch (action.payload.field) {
                default:
                    return {
                        ...state,
                        error: true,
                        competence: {}
                    }
            }
        case COMPETENCES_NEW:
            return {
                ...state
            }
        case COMPETENCES_NEW_SUCCESS:
            return {
                ...state,
                new_error: false,
                competence: action.payload,
                competences: state.competences.map(competence => competence.id === action.payload.id ? competence = action.payload : competence)
                // competences: [...state.competences, action.payload]
            }
        case COMPETENCES_NEW_ERROR:
            return {
                ...state,
                new_error: true
            }
        case COMPETENCES_EDIT:
            return {
                ...state,
            }
        case COMPETENCES_EDIT_SUCCESS:
            return {
                ...state,
                error: false,
                competence: action.payload,
                competences: state.competences.map(competence => competence.id === action.payload.id ? competence = action.payload : competence),
            }
        case COMPETENCES_EDIT_ERROR:
            return {
                ...state,
                error: true
            }
        case COMPETENCES_BY_TYPE_DDLIST:
            return {
                ...state,
                loading: true,
                competence: {}
            }
        case COMPETENCES_BY_TYPE_DDLIST_SUCCESS:
            return {
                ...state,
                competencesList: action.payload,
                loading: false,
                error: false
            }
        case COMPETENCES_BY_TYPE_DDLIST_ERROR:
            return {
                ...state,
                competencesList: [],
                loading: false,
                error: true,
                competence: {}
            }
        default:
            return state;
    }
}
