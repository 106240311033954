/* eslint-disable import/no-anonymous-default-export */
import {
    COMPANY_LIST,
    COMPANY_LIST_SUCCESS,
    COMPANY_LIST_ERROR,
    COMPANY_DDLIST,
    COMPANY_DDLIST_SUCCESS,
    COMPANY_DDLIST_ERROR,
 
} from '../types';

const initialState = {
    companies: [],
    company: {},
    loading: false,
    error: false
}

export default function(state = initialState, action){
    switch(action.type){
        case COMPANY_LIST:
            return {
                ...state,
                loading: true,
                company: {},
            }
        case COMPANY_LIST_SUCCESS:
            return {
                ...state,
                companies: action.payload,
                company: {},
                loading: false,
                error: false,
            }
        case COMPANY_LIST_ERROR:
            return {
                ...state,
                companies: [],
                company: {},
                loading: false,
                error: true,
            }
        case COMPANY_DDLIST:
            return {
                ...state,
                loading: true,
                company: {}
            }
        case COMPANY_DDLIST_SUCCESS:
            return {
                ...state,
                companies: action.payload,
                loading: false,
                error: false
            }
        case COMPANY_DDLIST_ERROR:
            return {
                ...state,
                companies: [],
                loading: false,
                error: true,
                company: {}
            }

        default:
            return state;
    }
}
