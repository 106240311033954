/* eslint-disable import/no-anonymous-default-export */
import {
    MAXDIFFCONFIG_GET,
    MAXDIFFCONFIG_GET_SUCCESS,
    MAXDIFFCONFIG_GET_ERROR ,
    MAXDIFFCONFIG_NEW,
    MAXDIFFCONFIG_NEW_SUCCESS,
    MAXDIFFCONFIG_NEW_ERROR,
    MAXDIFFCONFIG_EDIT,
    MAXDIFFCONFIG_EDIT_SUCCESS,
    MAXDIFFCONFIG_EDIT_ERROR,
    MAXDIFFCONFIG_BAREMO_GET,
    MAXDIFFCONFIG_BAREMO_GET_SUCCESS,
    MAXDIFFCONFIG_BAREMO_GET_ERROR
} from '../types';

const initialState = {
    maxdiffconfigs: [],
    maxdiffconfig: {},
    baremo: {},
    loading: false,
    error: false
}

export default function(state = initialState, action){
    switch(action.type){
        case MAXDIFFCONFIG_GET:
            return {
                ...state,
                loading: true
            }
        case MAXDIFFCONFIG_GET_SUCCESS:
            return {
                ...state,
                maxdiffconfig: action.payload,
                loading: false,
                error: false
            }
        case MAXDIFFCONFIG_GET_ERROR:
            return {
                ...state,
                error: true,
                maxdiffconfig: {}
            }
        case MAXDIFFCONFIG_NEW:
            return {
                ...state
            }
        case MAXDIFFCONFIG_NEW_SUCCESS:
            return {
                ...state,
                new_error: false,
                maxdiffconfig: action.payload,
                maxdiffconfigs: state.maxdiffconfigs.map(maxdiffconfig => maxdiffconfig.id === action.payload.id ? maxdiffconfig = action.payload : maxdiffconfig)
            }
        case MAXDIFFCONFIG_NEW_ERROR:
            return {
                ...state,
                new_error: true
            }
        case MAXDIFFCONFIG_EDIT:
            return {
                ...state,
            }
        case MAXDIFFCONFIG_EDIT_SUCCESS:
            return {
                ...state,
                error: false,
                maxdiffconfig: action.payload,
                maxdiffconfigs: state.maxdiffconfigs.map(maxdiffconfig => maxdiffconfig.id === action.payload.id ? maxdiffconfig = action.payload : maxdiffconfig),
            }
        case MAXDIFFCONFIG_EDIT_ERROR:
            return {
                ...state,
                error: true
            }
        case MAXDIFFCONFIG_BAREMO_GET:
            return {
                ...state,
            }
        case MAXDIFFCONFIG_BAREMO_GET_SUCCESS:
            return {
                ...state,
                error: false,
                baremo: action.payload,
                maxdiffconfigs: state.maxdiffconfigs.map(maxdiffconfig => maxdiffconfig.id === action.payload.id ? maxdiffconfig = action.payload : maxdiffconfig),
            }
        case MAXDIFFCONFIG_BAREMO_GET_ERROR:
            return {
                ...state,
                error: true
            }
        default:
            return state;
    }
}
