/* eslint-disable import/no-anonymous-default-export */
import {
    CATEGORIES_LIST,
    CATEGORIES_LIST_SUCCESS,
    CATEGORIES_LIST_ERROR,
    CATEGORIES_DDLIST,
    CATEGORIES_DDLIST_SUCCESS,
    CATEGORIES_DDLIST_ERROR,
    CATEGORIES_GET,
    CATEGORIES_GET_SUCCESS,
    CATEGORIES_GET_ERROR,
    CATEGORIES_GET_SEARCH,
    CATEGORIES_GET_SEARCH_SUCCESS,
    CATEGORIES_GET_SEARCH_ERROR,
    CATEGORIES_NEW,
    CATEGORIES_NEW_SUCCESS,
    CATEGORIES_NEW_ERROR,
    CATEGORIES_EDIT,
    CATEGORIES_EDIT_SUCCESS,
    CATEGORIES_EDIT_ERROR
} from '../types';

const initialState = {
    categories: [],
    categorie: {},
    loading: false,
    error: false
}

export default function(state = initialState, action){
    switch(action.type){
        case CATEGORIES_LIST:
            return {
                ...state,
                loading: true,
                categorie: {},
            }
        case CATEGORIES_LIST_SUCCESS:
            return {
                ...state,
                categories: action.payload,
                categorie: {},
                loading: false,
                error: false,
            }
        case CATEGORIES_LIST_ERROR:
            return {
                ...state,
                categories: [],
                categorie: {},
                loading: false,
                error: true,
            }
        case CATEGORIES_DDLIST:
            return {
                ...state,
                loading: true,
                categorie: {}
            }
        case CATEGORIES_DDLIST_SUCCESS:
            return {
                ...state,
                categories: action.payload,
                loading: false,
                error: false
            }
        case CATEGORIES_DDLIST_ERROR:
            return {
                ...state,
                categories: [],
                loading: false,
                error: true,
                categorie: {}
            }
        case CATEGORIES_GET:
            return {
                ...state,
                loading: true
            }
        case CATEGORIES_GET_SUCCESS:
            return {
                ...state,
                categorie: action.payload,
                loading: false,
                error: false
            }
        case CATEGORIES_GET_ERROR:
            return {
                ...state,
                error: true,
                categorie: {}
            }
        case CATEGORIES_GET_SEARCH:
            return {
                ...state,
                loading: true
            }
        case CATEGORIES_GET_SEARCH_SUCCESS:
            switch (action.payload.field) {
                default:
                    return {
                        ...state,
                        categorie: action.payload,
                        loading: false,
                        error: false
                    }
            }

        case CATEGORIES_GET_SEARCH_ERROR:
            switch (action.payload.field) {
                default:
                    return {
                        ...state,
                        error: true,
                        categorie: {}
                    }
            }
        case CATEGORIES_NEW:
            return {
                ...state
            }
        case CATEGORIES_NEW_SUCCESS:
            return {
                ...state,
                error: false,
                categories: [...state.categories, action.payload]
            }
        case CATEGORIES_NEW_ERROR:
            return {
                ...state,
                error: true
            }
        case CATEGORIES_EDIT:
            return {
                ...state,
            }
        case CATEGORIES_EDIT_SUCCESS:
            return {
                ...state,
                error: false,
                categories: action.payload,
                categories: state.categories.map(categorie => categorie.id === action.payload.id ? categorie = action.payload : categorie),
            }
        case CATEGORIES_EDIT_ERROR:
            return {
                ...state,
                error: true
            }
        default:
            return state;
    }
}
